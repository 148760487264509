const navItems = (applications: string[]) => ({
  dashboard: {
    visible: true,
    application: 'CORE',
    activeString: 'dashboard',
    requiredPermissions: [],
  },
  employees: {
    visible: true,
    application: 'CORE',
    activeString: 'employees',

    requiredPermissions: [],
  },
  payslips: {
    visible: applications.includes('PAYSLIPS'),
    application: 'PAYSLIPS',
    activeString: 'payslips',

    requiredPermissions: [
      'PAYSLIPS_MANAGER',
      'PAYSLIPS_UPLOADER',
      'PAYSLIPS_PUBLISHER',
    ],
  },
  p60s: {
    visible: applications.includes('PAYSLIPS'),
    application: 'P60S',
    activeString: 'p60s',

    requiredPermissions: [
      'PAYSLIPS_MANAGER',
      'PAYSLIPS_UPLOADER',
      'PAYSLIPS_PUBLISHER',
    ],
  },
  documents: {
    visible: applications.includes('DOCUMENTS'),
    application: 'DOCUMENTS',
    activeString: 'documents',

    requiredPermissions: [
      'DOCUMENTS_MANAGER',
      'DOCUMENTS_UPLOADER',
      'DOCUMENTS_APPROVER',
      'DOCUMENTS_PUBLISHER',
    ],
  },
  timesheets: {
    visible: applications.includes('TIMESHEETS'),
    application: 'TIMESHEETS',
    activeString: 'timesheets',
    requiredPermissions: ['TIMESHEETS_MANAGER', 'TIMESHEETS_APPROVER'],
  },
  messages: {
    visible: true,
    application: 'CORE',
    activeString: 'messages',
    requiredPermissions: [],
  },
  settings: {
    visible: true,
    application: 'CORE',
    activeString: 'settings',

    requiredPermissions: [
      'CORE_MANAGER',
      'PAYSLIPS_MANAGER',
      'DOCUMENTS_MANAGER',
      'TIMESHEETS_MANAGER',
      'STARTERS_LEAVERS_MANAGER',
      'WAGE_VERIFICATION_MANAGER',
    ],
  },
});

export default navItems;
