import moment from 'moment';

const subtractUnitOfTime = (
  date: any,
  amount: number,
  unit: moment.unitOfTime.DurationConstructor
): Date => {
  const originalDate = moment(date);
  const newDate = originalDate.subtract(amount, unit);
  return newDate.toDate();
};

export default subtractUnitOfTime;
