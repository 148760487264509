const formatCurrency = (
  value = 0,
  printDecimal = true,
  printSymbol = true
): string => {
  if (value === null) return 'NaN';
  return `${printSymbol ? '£' : ''}${
    printDecimal ? value.toFixed(2) : Math.floor(value)
  }`;
};

export default formatCurrency;
