/* eslint-disable react/jsx-no-bind */
import { Layout as AppLayout } from '@os/ui';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useScreenSize } from '@os/hooks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import auth from '../auth';

function Layout({ children }) {
  const { sm, md } = useScreenSize();
  const { t } = useTranslation(['translation']);
  const { name: companyName } = useSelector(state => state.company.details);

  const { pathname } = useLocation();
  const showTitle = pathname !== '/service-deprecated';

  function logout() {
    auth.logout();
  }

  function isMobile() {
    return sm || md;
  }

  return (
    <AppLayout
      title={t('app-title')}
      logout={logout}
      companyName={companyName}
      isLoggedIn={auth.isLoggedIn()}
      pageTitle=""
      mobile={isMobile()}
      footerText={`${t('footer')} ${new Date().getFullYear()}`}
      showAppMenu={false}
      application="CORE"
      logoutLabel={t('logout-label')}
      changeCompanyLabel={t('change-company-label')}
      helpLabel={t('help-label')}
      helpUrl="https://my.sage.co.uk/public/help.aspx#/customer/support/payroll"
      personType="SHARED"
      showTitle={showTitle}
    >
      {children}
    </AppLayout>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default memo(Layout);
