function dec2hex(dec: number): string {
  return dec < 10 ? `0${dec}` : dec.toString(16);
}

/**
 * Generates a random string of hex numbers
 */
function generateRandomString(len = 20): string {
  const arr = new Uint8Array(len / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

export default generateRandomString;
