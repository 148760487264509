import thunk from 'redux-thunk';
import { root } from '@os/state-management';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { composeWithDevTools } from 'redux-devtools-extension';
import history from './history';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['company', 'employee'],
};

const persistedReducer = persistReducer(persistConfig, root(history));

function reduxStore(initialState = {}) {
  const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
  );
  const persistor = persistStore(store);

  return { store, persistor };
}

export default reduxStore;
