import moment from 'moment';

// Import moment settings for supported languages here
// eslint-disable-next-line import/no-unresolved
import 'moment/locale/en-gb';

const navigator = window.navigator as any;
const userLocale = navigator.userLanguage || navigator.language;
const defaultLocale = 'en-gb';

// Fall back to the default locale if the user locale is not available
const locale =
  userLocale && moment.locales().includes(userLocale.toLowerCase())
    ? userLocale.toLowerCase()
    : defaultLocale;

// Set the global locale
moment.locale(locale);
