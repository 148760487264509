import {
  CarbonProvider,
  CarbonScopedTokensProvider,
  DelayedLoading,
  GlobalStyles,
  ThemeWithBreakpoints,
  Toaster,
} from '@os/ui';
import { ConnectedRouter } from 'connected-react-router';
import React, { memo, Suspense } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { ScreenSizeProvider } from '@os/hooks';
import { Analytics, resetFlash } from '@os/state-management';
import history from '../history';
import reduxStore from '../store';
import Layout from './containers/Layout';
import Routes from './Routes';

function ToasterWrapper() {
  const { flashType, message, timeout } = useSelector(state => state.flash);
  const dispatch = useDispatch();
  return (
    <Toaster
      dispatch={dispatch}
      flashType={flashType}
      message={message}
      resetFlash={resetFlash}
      timeout={timeout}
    />
  );
}

function App() {
  const { store, persistor } = reduxStore();

  return (
    <CarbonProvider theme={ThemeWithBreakpoints}>
      <GlobalStyles />
      <CarbonScopedTokensProvider>
        <ScreenSizeProvider>
          <Suspense fallback={<DelayedLoading delay={500} />}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                {bootstrapped => {
                  if (!bootstrapped) {
                    return null;
                  }

                  return (
                    <ConnectedRouter history={history}>
                      <Analytics
                        googleAnalyticsKey={process.env.REACT_APP_GA_KEY}
                      />
                      <Layout>
                        <>
                          <ToasterWrapper />
                          <Routes />
                        </>
                      </Layout>
                    </ConnectedRouter>
                  );
                }}
              </PersistGate>
            </Provider>
          </Suspense>
        </ScreenSizeProvider>
      </CarbonScopedTokensProvider>
    </CarbonProvider>
  );
}

export default memo(App);
