/* eslint-disable @typescript-eslint/explicit-function-return-type */
import navItems from './nav-item-list';

import { routeRegex } from '../../global/regex';
import { applicationsEnum } from '../../global/enums';

const generateNav = (
  companyId: string,
  applications: string[],
  permissions: string[],
  t: Function,
  _: string,
  currentRoute: string
) => {
  const menuItems = navItems(applications);

  return companyId
    ? Object.keys(menuItems).map(item => {
        const { activeString, requiredPermissions } = menuItems[item];

        const activeRouteTester = routeRegex('employer', activeString);
        const hasRequiredPermissions =
          requiredPermissions.length === 0 ||
          requiredPermissions.some(permission => {
            return permissions.includes(permission);
          });

        const hasOBLicence = applications.includes(
          applicationsEnum.startersLeavers
        );

        if (item === 'employees' && !hasOBLicence) {
          return {
            id: item,
            label: t(`menu.${item}`),
            visible: menuItems[item].visible,
            disabled: !hasRequiredPermissions,
            activepage: activeRouteTester.test(currentRoute),
            to: `/employer/${companyId}/employees/list`,
          };
        }

        return {
          id: item,
          label: t(`menu.${item}`),
          visible: menuItems[item].visible,
          disabled: !hasRequiredPermissions,
          activepage: activeRouteTester.test(currentRoute),
          to: `/employer/${companyId}/${item}`,
        };
      })
    : [];
};

export default generateNav;
