/**
 * Indicates whether the supplied value is a valid number,
 * or a string which can be converted to a valid number.
 */
export function isNumeric(value: any) {
  if (typeof value === 'number' && !Number.isNaN(value)) {
    return true;
  }

  if (typeof value !== 'string') {
    return false;
  }

  return !Number.isNaN(Number(value)) && !Number.isNaN(parseFloat(value));
}
