import {
  companyLogout,
  employeeLogout,
  payTypesLogout,
  settingsLogout,
} from '@os/state-management';

export default function clearReduxOnLogout() {
  return async dispatch => {
    dispatch(companyLogout());
    dispatch(employeeLogout());
    dispatch(payTypesLogout());
    dispatch(settingsLogout());
  };
}
