import { useTitle } from '@os/hooks';
import { Box, StylableHeading, Image, Link, Typography } from '@os/ui';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import PageLoadErrorImageSrc from '../../../public/images/404-not-found.png';

function PageNavigationError() {
  const lang = `page-nav-error`;

  const { t, ready: translationReady } = useTranslation(['translation', lang]);

  useTitle(t(`${lang}:title`));

  if (!translationReady) {
    return null;
  }

  return (
    <>
      <StylableHeading
        separator
        divider={false}
        title={t(`${lang}:title`)}
        align="center"
        variant="h1-large"
        paddingProps={{ pt: 0 }}
        mb={0}
      />
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        alignContent="center"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="p" style={{ textAlign: 'center' }}>
          {t(`${lang}:errorText`)}
        </Typography>
        <Link iconType="chevron_left" to="/" mb={3}>
          {t(`${lang}:errorLinkText`)}
        </Link>
        <Image
          alt={t(`${lang}:imageAltText`)}
          maxWidth={256}
          padding="16px 0 0 0"
          src={PageLoadErrorImageSrc}
        />
      </Box>
    </>
  );
}

export default memo(PageNavigationError);
