/* eslint-disable @typescript-eslint/no-this-alias */
const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, timeout);
  };
};
export default debounce;
