import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AccessDenied from './pages/AccessDenied';
import ESSV1DeprecationInfo from './pages/ESSV1DeprecationInfo';
import PageNavigationError from './pages/PageNavigationError';
import Welcome from './pages/Welcome';
import Migration from './pages/Migration';
import SessionExpired from './pages/SessionExpired';

function Routes() {
  return (
    <Switch>
      <Route exact path="/access-denied" component={AccessDenied} />
      <Route exact path="/migration" component={Migration} />
      <Route exact path="/page-load-error" component={PageNavigationError} />
      <Route exact path="/page-not-found" component={PageNavigationError} />
      <Route
        exact
        path="/service-deprecated"
        component={ESSV1DeprecationInfo}
      />
      <Route exact path="/session-expired" component={SessionExpired} />
      <Route exact path="/" component={Welcome} />
      <Redirect to="/page-not-found" />
    </Switch>
  );
}

export default Routes;
