import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const loadPath = `${process.env.REACT_APP_LANDING_URI}/locales/{{lng}}/{{ns}}.json`;

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath,
      queryStringParams: { v: process.env.CACHE_BUSTING_ID },
    },
  });

export default i18n;
