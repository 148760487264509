/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import { useTitle } from '@os/hooks';
import { Box, StylableHeading, Link, Typography, Message } from '@os/ui';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  margin-bottom: 15px;
`;

function Migration() {
  const { t, ready: translationReady } = useTranslation([
    'translation',
    'migration',
  ]);

  useTitle(t('migration:title'));

  if (!translationReady) {
    return null;
  }
  return (
    <>
      <StylableHeading
        separator
        divider={false}
        title={t('migration:title')}
        align="center"
        variant="h1-large"
        paddingProps={{ pt: 2 }}
      />
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        alignContent="center"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="p" style={{ textAlign: 'center' }}>
          {t('migration:pageTitle')}
        </Typography>
        <Typography variant="p" style={{ textAlign: 'center' }}>
          {t('migration:redirectInfo')}
        </Typography>
        <Typography variant="p" style={{ textAlign: 'center' }}>
          {t('migration:bookmarkInfo')}
        </Typography>

        <StyledLink href="/" to="/">
          {`${window.location.protocol}//${window.location.hostname}`}
        </StyledLink>

        <Typography variant="p" style={{ textAlign: 'center' }}>
          {t('migration:continueInfo')}
        </Typography>

        <StyledLink href="/" to="/">
          {t('migration:continue')}
        </StyledLink>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        bg="white"
        mt="1%"
        ml="15%"
        mr="15%"
      >
        <Message
          variant="warning"
          title={
            <Typography variant="h4">
              {t(`migration:serviceContinuityTitle`)}
            </Typography>
          }
          mb={4}
        />
        <Typography variant="p" mx={2}>
          {t(`migration:serviceContinuityInfo1`)}{' '}
          <b>{t(`migration:serviceContinuityDate`)}</b>{' '}
          {t(`migration:serviceContinuityInfo2`)}
        </Typography>
      </Box>
    </>
  );
}

export default memo(Migration);
