// eslint-disable-next-line import/prefer-default-export
export function getInitials(name: string) {
  if (!name) {
    return '';
  }

  const partA = name[0] || '';
  const partB = name[1] || '';

  let initials = `${partA}${partB}`;
  const labelParts = name.split(' ').filter(Boolean);
  if (labelParts.length > 1) {
    initials = `${labelParts[0][0]}${labelParts[1][0]}`;
  }
  return initials;
}
