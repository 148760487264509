import { useTitle } from '@os/hooks';
import { Box, Button, StylableHeading, Typography } from '@os/ui';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function AccessDenied() {
  const lang = 'access-denied';
  const { t, ready: translationReady } = useTranslation(['translation', lang]);
  const {
    details: { id },
  } = useSelector(state => state.company);
  const {
    details: { id: employmentId },
    company: { id: companyId },
  } = useSelector(state => state.employee);

  const url = employmentId
    ? `/employee/${companyId}/dashboard`
    : `/employer/${id}/dashboard`;

  useTitle(t(`${lang}:title`));

  if (!translationReady) {
    return null;
  }

  return (
    <>
      <StylableHeading
        title={t(`${lang}:title`)}
        separator
        divider={false}
        align="center"
        variant="h1-large"
        paddingProps={{ pt: 4 }}
      />
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        alignContent="center"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="p" style={{ textAlign: 'center' }}>
          {t(`${lang}:information`)}
        </Typography>

        <Button
          buttonType="tertiary"
          onClick={() => {
            window.location = url;
          }}
        >
          {t(`${lang}:buttonText`)}
        </Button>
      </Box>
    </>
  );
}

export default memo(AccessDenied);
