import { Auth } from '@os/client-auth';
import history from '../history';

const auth = new Auth(
  {
    clientID: process.env.REACT_APP_CLIENT_ID,
    domain: process.env.REACT_APP_DOMAIN,
    landingUri: process.env.REACT_APP_LANDING_URI,
    audience: process.env.REACT_APP_AUDIENCE,
  },
  history
);

export default auth;
