import moment from 'moment';

const addUnitOfTime = (
  date: any,
  amount: number,
  unit: moment.unitOfTime.DurationConstructor
): Date => {
  const originalDate = moment(date);
  const newDate = originalDate.add(amount, unit);
  return newDate.toDate();
};

export default addUnitOfTime;
