export const applicationsEnum = {
  core: 'CORE',
  payslips: 'PAYSLIPS',
  documents: 'DOCUMENTS',
  timesheets: 'TIMESHEETS',
  hr: 'HR',
  startersLeavers: 'STARTERS_LEAVERS',
  wageVerification: 'WAGE_VERIFICATION',
};

export const groupTypesEnum = {
  permissions: 'PERMISSIONS',
  userCreated: 'USER_CREATED',
};

export const settingTypesEnum = {
  payslips: 'PayslipSetting',
  timesheets: 'TimesheetSetting',
  documents: 'DocumentSetting',
  wageVerification: 'WageVerificationSetting',
};

export const messageClassEnums = {
  important: 'IMPORTANT',
  normal: 'NORMAL',
};

export const permissionTypesEnum = {
  manager: 'Manager',
  publisher: 'Publisher',
  approver: 'Approver',
  uploader: 'Uploader',
};

export const permissionTypesUpperEnum = {
  manager: 'MANAGER',
  publisher: 'PUBLISHER',
  approver: 'APPROVER',
  uploader: 'UPLOADER',
};

export const payslipStatusEnum = {
  pending: 'PENDING',
  published: 'PUBLISHED',
};

export const personTypesEnum = {
  employer: 'EMPLOYER',
  employee: 'EMPLOYEE',
};

export const documentPublicationStatusEnum = {
  pending: 'PENDING',
  published: 'PUBLISHED',
  approved: 'APPROVED',
  rejected: 'REJECTED',
};

export const documentPublicationActionEnum = {
  publish: 'PUBLISH',
  approve: 'APPROVE',
  reject: 'REJECT',
};

export const documentTypesEnum = {
  company: 'COMPANY',
  employee: 'EMPLOYEE',
};

export const timesheetStatusEnum = {
  saved: 'SAVED',
  submitted: 'SUBMITTED',
  approved: 'APPROVED',
  rejected: 'REJECTED',
  pending: 'PENDING',
  various: 'VARIOUS',
};

export const timesheetSortsEnum = {
  timesheetDate: 'TIMESHEET_DATE',
  status: 'STATUS',
};

export const payTypeInputFrequencyEnum = {
  daily: 'DAILY',
  weekly: 'WEEKLY',
};

export const payTypeInputMethodEnum = {
  time: 'TIME',
  units: 'UNITS',
};

export const payFrequencyEnum = {
  none: 'NONE',
  annually: 'ANNUALLY',
  weekly: 'WEEKLY',
  fortnightly: 'FORTNIGHTLY',
  fourWeekly: 'FOUR_WEEKLY',
  monthly: 'MONTHLY',
};

export const permissionsEnum = {
  coreManager: 'CORE_MANAGER',
  payslipsManager: 'PAYSLIPS_MANAGER',
  payslipsUploader: 'PAYSLIPS_UPLOADER',
  payslipsPublisher: 'PAYSLIPS_PUBLISHER',
  documentsManager: 'DOCUMENTS_MANAGER',
  documentsUploader: 'DOCUMENTS_UPLOADER',
  documentsPublisher: 'DOCUMENTS_PUBLISHER',
  documentsApprover: 'DOCUMENTS_APPROVER',
  timesheetsManager: 'TIMESHEETS_MANAGER',
  timesheetsApprover: 'TIMESHEETS_APPROVER',
  hrManager: 'HR_MANAGER',
  startersLeaversManager: 'STARTERS_LEAVERS_MANAGER',
  startersLeaversUploader: 'STARTERS_LEAVERS_UPLOADER',
};

export const sortOrderEnum = {
  asc: 'ASC',
  desc: 'DESC',
};

export const p60SortsEnum = {
  taxYear: 'TAX_YEAR',
};

export const employeeSortsEnum = {
  email: 'EMAIL',
  surname: 'SURNAME',
};

export const restrictionAccessStatusEnum = {
  accessAll: 'ACCESS_ALL',
  accessNone: 'ACCESS_NONE',
  accessRestricted: 'ACCESS_RESTRICTED',
};

export const starterStatusEnum = {
  saved: 'SAVED',
  submitted: 'SUBMITTED',
  accepted: 'ACCEPTED',
  rejected: 'REJECTED',
};

export const starterSortsEnum = {
  surname: 'SURNAME',
  firstname: 'FIRSTNAME',
  employmentStartDate: 'EMPLOYMENT_START_DATE',
  status: 'STATUS',
};

export const leaverStatusEnum = {
  saved: 'SAVED',
  submitted: 'SUBMITTED',
  accepted: 'ACCEPTED',
  rejected: 'REJECTED',
};

export const leaverSortsEnum = {
  surname: 'SURNAME',
  firstname: 'FIRSTNAME',
  employmentEndDate: 'EMPLOYMENT_END_DATE',
  status: 'STATUS',
};

export const enums = {
  applicationsEnum,
  groupTypesEnum,
  settingTypesEnum,
  permissionTypesEnum,
  payslipStatusEnum,
  personTypesEnum,
  documentPublicationStatusEnum,
  documentPublicationActionEnum,
  documentTypesEnum,
  timesheetStatusEnum,
  payFrequencyEnum,
  permissionsEnum,
  sortOrderEnum,
  p60SortsEnum,
  employeeSortsEnum,
  permissionTypesUpperEnum,
  restrictionAccessStatusEnum,
  starterStatusEnum,
  starterSortsEnum,
  leaverStatusEnum,
  leaverSortsEnum,
};
