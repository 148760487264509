import zeroPad from './zero-pad';

const formatDate = (dateString: string): string => {
  const realDate = new Date(dateString);
  return `${zeroPad(realDate.getDate())}/${zeroPad(
    realDate.getMonth() + 1
  )}/${realDate.getFullYear()}`;
};

export default formatDate;
