/**
 * Converts a value in GraphQL enum casing "LIKE_THIS"
 * to a value in title casing "Like This"
 */
function enumToTitleCase(value: string): string {
  if (!value) {
    return value;
  }

  return value
    .split('_')
    .map(
      (txt): string => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    )
    .join(' ');
}

export default enumToTitleCase;
